import { Component, AfterViewInit } from '@angular/core';

import { DataService } from '../data.service';
import { PositionInfo } from '../models/positionInfo';

declare var google: any;

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements AfterViewInit {

  private map: any;
  googleMapsStyle = [];
  currPosition: any;
  trackPosition = false;
  interval: any;
  positionInfo: PositionInfo = new PositionInfo();

  color = {
    blue: '#375E97',
    orange: '#FB6542',
    yellow: '#FFBB00',
    green: '#3F681C',
  };

  constructor(private dataService: DataService) {
    this.googleMapsStyle = this.dataService.getGoogleMapsStyle();
  }

  ngAfterViewInit() {
    (<any>window).googleMapsReady = this.onMapsReady.bind(this);
    const script = document.createElement('script');
    script.setAttribute('defer', '');
    script.setAttribute('async', '');
    script.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(script);
    script.src = 'https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyBKyfH4Mzh7tMwufn2W0yHTCDIDDXa2LTY&callback=googleMapsReady';
  }

  onMapsReady() {
    const mapOptions = {
      zoom: 5,
      center: new google.maps.LatLng(48, 8),
      disableDefaultUI: true,
      styles: this.googleMapsStyle
    };
    this.map = new google.maps.Map(document.getElementById('map'), mapOptions);

    this.currPosition = new google.maps.Marker({
      position: { lat: 0, lng: 0 },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        strokeWeight: 3,
        strokeColor: this.color.yellow,
        scale: 6
      },
      map: this.map
    });

    if (navigator.geolocation) {
      this.getUserPosizion(true);
    } else { console.log('Position : "Geolocation is not supported by this browser or disabled"'); }
  }

  prova() {
    this.trackPosition = !this.trackPosition;
    if (this.trackPosition) {
      this.interval = setInterval(() => this.getUserPosizion(true), 3000);
    } else { clearInterval(this.interval); }
  }

  getUserPosizion(trackPos?: boolean) {
    navigator.geolocation.getCurrentPosition((pos) => {
      const crd = pos.coords;
      const sGetPos = this.currPosition.getPosition();
      const changeFlag = (sGetPos.lat().toFixed(12) === crd.latitude.toFixed(12) &&
        sGetPos.lng().toFixed(12) === crd.longitude.toFixed(12)) ? false : true;
      if (changeFlag) {
        this.positionInfo.setInfo(pos.timestamp, crd.latitude, crd.longitude, crd.accuracy, crd.altitude, crd.altitudeAccuracy);

        console.log(this.positionInfo);

        this.currPosition.setPosition(new google.maps.LatLng(crd.latitude, crd.longitude));

        if (trackPos) {
          this.map.setCenter(new google.maps.LatLng(crd.latitude, crd.longitude));
          this.map.setZoom(13);
        }
      }

    });
  }
}
