import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

@Injectable()
export class DataService {

  constructor(private http: Http) { }

  firebaseUrl = 'https://trace-footprints-1499428350677.firebaseio.com/';
  nodeUrl = 'http://node.marcobastianon.it:4000/json';
  googleStyle = [
    {
      'elementType': 'geometry',
      'stylers': [{ 'color': '#f5f5f5' }]
    }, {
      'elementType': 'geometry.stroke',
      'stylers': [{ 'color': '#434a5c' }]
    }, {
      'elementType': 'labels.icon',
      'stylers': [{ 'visibility': 'off' }]
    }, {
      'elementType': 'labels.text',
      'stylers': [{ 'color': '#444b5d' }, { 'weight': 4 }]
    }, {
      'elementType': 'labels.text.fill',
      'stylers': [{ 'color': '#444b5d' }]
    }, {
      'elementType': 'labels.text.stroke',
      'stylers': [{ 'color': '#fafafa' }]
    }, {
      'featureType': 'administrative.land_parcel',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'color': '#bdbdbd' }]
    }, {
      'featureType': 'administrative.province',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#444b5d' }]
    }, {
      'featureType': 'landscape',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#e6e6e6' }]
    }, {
      'featureType': 'landscape.man_made',
      'elementType': 'geometry.stroke',
      'stylers': [{ 'color': '#e6e6e6' }]
    }, {
      'featureType': 'landscape.natural.landcover',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#e6e6e6' }]
    }, {
      'featureType': 'landscape.natural.terrain',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#cccccc' }]
    }, {
      'featureType': 'landscape.natural.terrain',
      'elementType': 'geometry.stroke',
      'stylers': [{ 'color': '#4cb372' }, { 'visibility': 'on' }]
    }, {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#eeeeee' }]
    }, {
      'featureType': 'poi',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'color': '#757575' }]
    }, {
      'featureType': 'poi.government',
      'elementType': 'labels.text',
      'stylers': [{ 'color': '#e1b164' }]
    }, {
      'featureType': 'poi.medical',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#cf5854' }]
    }, {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#e5e5e5' }]
    }, {
      'featureType': 'poi.park',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#76b38e' }]
    }, {
      'featureType': 'poi.park',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'color': '#77a4a2' }]
    }, {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#ffffff' }]
    }, {
      'featureType': 'road.arterial',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'color': '#757575' }]
    }, {
      'featureType': 'road.highway',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#dadada' }]
    }, {
      'featureType': 'road.highway',
      'elementType': 'labels.icon',
      'stylers': [{ 'color': '#cf5854' }]
    }, {
      'featureType': 'road.highway.controlled_access',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#e1b164' }]
    }, {
      'featureType': 'road.local',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'color': '#9e9e9e' }]
    }, {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#e5e5e5' }]
    }, {
      'featureType': 'transit.station',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#eeeeee' }]
    }, {
      'featureType': 'transit.station.airport',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#b392ad' }]
    }, {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#c9c9c9' }]
    }, {
      'featureType': 'water',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#77a4a2' }]
    }, {
      'featureType': 'water',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'color': '#9e9e9e' }]
    }
  ];

  getGoogleMapsStyle() {
    return this.googleStyle;
  }

  getAllPosition() {
    const url: string = this.firebaseUrl + 'marco/coordinate/.json';
    return this.http.get(url);
  }

  getTime() {
    return this.convertTimestamp(new Date());
  }

  convertTimestamp(time: Date): string {
    const hours = time.getHours();
    const minutes = '0' + time.getMinutes();
    const seconds = '0' + time.getSeconds();
    const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime;
  }
}
