export class PositionInfo {
  timestamp = '00:00:00';
  latitude = 0;
  longitude = 0;
  accuracy = 0;
  altitude = 0;
  altitudeAccuracy = 0;

  constructor() { }

  setInfo(timestamp: number, latitude: number, longitude: number, accuracy: number, altitude: number, altitudeAccuracy: number) {
    this.timestamp = this.convertTimestamp(new Date(timestamp));
    this.latitude = parseFloat(latitude.toFixed(12));
    this.longitude = parseFloat(longitude.toFixed(12));
    this.accuracy = accuracy;
    this.altitude = altitude;
    this.altitudeAccuracy = altitudeAccuracy;
  }

  private convertTimestamp(time: Date): string {
    const hours = time.getHours();
    const minutes = '0' + time.getMinutes();
    const seconds = '0' + time.getSeconds();
    const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime;
  }

}
