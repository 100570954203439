import { Component, Input } from '@angular/core';
import { PositionInfo } from '../models/positionInfo';

@Component({
  selector: 'app-position-info',
  templateUrl: './position-info.component.html',
  styleUrls: ['./position-info.component.scss']
})
export class PositionInfoComponent {

  @Input() info: PositionInfo;

  constructor() { }

}
